"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useSession } from "next-auth/react";

const NotFound = () => {
  const { status } = useSession();
  const isAuth = status === "authenticated" ? true : false;
  return (
    <>
      <div className="h-[100vh] bg-white flex flex-col  justify-center items-center ">
        <Image
          src="/NotFound/404.png"
          width={208}
          height={176}
          alt="Error Page"
        />
        <h1 className="mt-5 text-lg sm:text-2xl font-bold  text-black text-center">
          404
        </h1>
        <h1 className="mt-2 text-sm sm:text-xl font-bold  text-black text-center ">
          Sorry, the page you visited does not exist.
        </h1>
        <Link
          href={isAuth ? "/dashboard" : "/"}
          className="mt-7 flex justify-center items-center bg-black rounded-md p-4 text-xl font-bold text-white "
        >
          {isAuth ? "Go to Dashboard" : "Back Home"}
        </Link>
      </div>
    </>
  );
};

export default NotFound;
